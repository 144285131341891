import React from 'react'
import classnames from 'classnames'
import Slider from 'react-slick'
import './SlickSlider.scss'
import { inner, imageWrapper, photoCredentials, image as imageStyle } from './SlickSlider.module.scss'

export default class SlickSlider extends React.Component {
  render() {
    const settings = {
      arrows: false,
      dots: true,
      speed: 1250,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      fade: true,
      cssEase: 'linear 0s',
      ...this.props.settings,
    }
    return (
      <div className={classnames(inner, 'team-slider', this.props.className)}>
        <Slider {...settings}>
          {this.props.images.map((image, index) => (
            <div key={index} className={classnames(imageWrapper, this.props.imageWrapperClassName)}>
              <div className={`${photoCredentials} photo-credentials`}>{image.imageCredit}</div>
              <div className={classnames(imageStyle, this.props.imageClassName)} style={{ backgroundImage: `url(${image.image})` }} />
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}
