import * as React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import {
  container,
  show,
  fullScreen as fullScreenStyle,
  transparent as transparentStyle,
  spinner,
  spinnerInner,
  loader,
  message,
} from './Loader.module.scss'

export const Loader = ({ fullScreen, transparent }) => {
  const className = classnames(container, show, fullScreen && fullScreenStyle, transparent && transparentStyle)
  return (
    <div className={className}>
      <div className={loader}>
        <div className={spinner}>
          <div className={spinnerInner} />
        </div>
        <div className={classnames(message, show)} />
      </div>
    </div>
  )
}

Loader.propTypes = {
  fullScreen: PropTypes.bool,
  transparent: PropTypes.bool,
}

Loader.defaultProps = {
  fullScreen: false,
  transparent: false,
}

export default Loader
