// extracted by mini-css-extract-plugin
export var bioContent = "emso-TeamMember-module__bioContent";
export var bioInfo = "emso-TeamMember-module__bioInfo";
export var bioTitle = "emso-TeamMember-module__bioTitle";
export var content = "emso-TeamMember-module__content";
export var memberName = "emso-TeamMember-module__memberName";
export var openTeamMember = "emso-TeamMember-module__openTeamMember emso-TeamMember-module__teamMember";
export var photoCred = "emso-TeamMember-module__photoCred";
export var plus = "emso-TeamMember-module__plus";
export var positionContent = "emso-TeamMember-module__positionContent";
export var positionTitle = "emso-TeamMember-module__positionTitle";
export var teamImage = "emso-TeamMember-module__teamImage";
export var teamMember = "emso-TeamMember-module__teamMember";