import * as React from 'react'
import plusSign from './plus-sign.svg'
import {
  openTeamMember,
  teamImage,
  teamMember,
  memberName,
  positionContent,
  bioContent,
  bioTitle,
  plus,
  bioInfo,
  photoCred,
  positionTitle,
  content,
} from './TeamMember.module.scss'
import { getImageSource } from '../../utils/helpers/image'
import ChangesBadge from '../ChangesBadge/ChangesBadge'

export class TeamMember extends React.Component {
  toggle() {
    if (!this.props.open) {
      this.props.onOpen(this.props.id)
    }
    if (this.props.open) {
      this.props.onClose(this.props.id)
    }
  }

  render() {
    const { image, firstName, lastName, title, bio, open, previewStatus, watermark, id } = this.props
    return (
      <li
        id={`TeamMember-${firstName}-${lastName}`}
        data-position={id}
        className={open ? openTeamMember : teamMember}
        onClick={this.toggle.bind(this)}
      >
        <div className={teamImage} style={{ backgroundImage: `url(${getImageSource(image)})` }}>
          <div className={content}>
            <div className={positionContent}>
              <h1 className={memberName}>{`${firstName} ${lastName}`}</h1>
              <div className={positionTitle}>{title}</div>
            </div>
            <div className={bioContent}>
              <div className={bioTitle}>Biography</div>
              <img className={plus} src={plusSign} alt="plus" />
              <div className={bioInfo}>
                <p>{bio}</p>
              </div>
              {watermark && <div className={photoCred}>Photo by {watermark}</div>}
            </div>
          </div>
          <ChangesBadge status={previewStatus} />
        </div>
      </li>
    )
  }
}
