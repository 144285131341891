import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../layouts/Layout'
import { heroData } from '../data/team'
import Hero from '../components/Hero/Hero'
import NextSection from '../components/NextSection/NextSection'
import heroImage from './images/city-background.jpg'
import * as style from '../components/Team/team.module.scss'
import Team from '../components/Team/Team'

const team = () => {
  return (
    <Layout className={style.teamPage} hasDownArrow>
      <Helmet>
        <title>Team - Emso</title>
        <meta property="og:title" content="Team" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`https://www.emso.com${heroImage}`} />
        <meta name="description" content="Emso is an independent asset manager. Ownership is shared between more than 20 employees."/>
      </Helmet>
      <Hero image={heroImage} {...heroData} />
      <NextSection />
      <Team />
    </Layout>
  )
}

export default team
