
export const DEFAULT_ORDER = []

export const sortPeople = (peopleInput) => {
  const people = [...peopleInput]
  const sortedPeople = []
  DEFAULT_ORDER.forEach(({firstName, lastName}) => {
    const existingIndex = people.findIndex(item => item.firstName === firstName && item.lastName === lastName)
    if(existingIndex !== -1) {
      sortedPeople.push(...people.splice(existingIndex, 1))
    }
  })
  sortedPeople.push(...people)
  return sortedPeople
}
